.BlogsPage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5rem;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
  }
  .blog {
    background-color: #161925;
    width: 100%;
    padding: 1rem;
    overflow: hidden;
    .blog-content {
      overflow: hidden;
      padding-bottom: 2rem;
      img {
        width: 100%;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
        padding-bottom: 2rem;
      }
      .blog-link {
        color: inherit;
        font-family: inherit;
        text-decoration: none;
        font-size: 2rem;
        padding-bottom: 2rem;
        transition: all 0.4s ease-in-out;
        &:hover {
          color: #037fff;
        }
      }
    }
  }
}

.b-title {
  margin-bottom: 4rem;
}
